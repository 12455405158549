import { Container, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Button, IconButton } from "gatsby-theme-material-ui";
import React from "react";
import { useSelectedPropertyContext } from "./../components/SelectedPropertyProvider";
import {
  useClientSubscription,
  useCreateClientMutation,
} from "../__generated__/types";
import { translations } from "../translations";
import dayjs from "dayjs";
import { Close } from "@mui/icons-material";
import { ClientForm } from "../components/forms/Clientform";
import { useSnackbar } from "notistack";

const CreateClientFormDialog = () => {
  const { selected_locale, selected } = useSelectedPropertyContext();
  const [open, setOpen] = React.useState(false);
  const [insertClient] = useCreateClientMutation();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        sx={{ mb: 1, alignSelf: "flex-end" }}
        onClick={() => setOpen(true)}
      >
        {translations[selected_locale].client_new}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {translations[selected_locale].new_reservation}
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <ClientForm
            onSubmit={async (values) => {
              await insertClient({
                variables: {
                  object: { ...values.client, property_id: selected },
                },
              });
              enqueueSnackbar(
                `${translations[selected_locale].client} ${values.client.first_name} ${values.client.last_name} ${translations[selected_locale].is_succesfully_saved}`,
                {
                  variant: "success",
                }
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const ClientPage = () => {
  const { selected, selected_locale } = useSelectedPropertyContext();
  const { data, loading, error } = useClientSubscription({
    variables: { property_id: selected },
  });
  return (
    <>
      <Container
        sx={{ mt: 2, display: "flex", flexDirection: "column" }}
        maxWidth="xl"
      >
        <CreateClientFormDialog />
        <DataGrid
          error={error?.message}
          loading={loading}
          autoHeight
          checkboxSelection={false}
          rows={
            data?.client.map((c, index, arr) => ({
              ...c,
              number: arr.length - index,
              reservation_count: c.reservations_aggregate.aggregate?.count,
            })) || []
          }
          columns={[
            {
              field: "id",
              headerName: "id",
              hide: true,
            },
            {
              field: "number",
              headerName: translations[selected_locale].number_label,
              minWidth: 50,
              flex: 1,
              filterable: false,
            },
            {
              field: "email",
              headerName: translations[selected_locale].email,
              minWidth: 150,
              flex: 1,
            },
            {
              field: "first_name",
              headerName: translations[selected_locale].given_name,
              minWidth: 150,
              flex: 1,
            },
            {
              field: "last_name",
              headerName: translations[selected_locale].family_name,
              minWidth: 150,
              flex: 1,
            },
            {
              field: "phone_number",
              headerName: translations[selected_locale].phone_number,
              minWidth: 150,
              flex: 1,
            },
            {
              field: "reservation_count",
              headerName: translations[selected_locale].reservations,
              minWidth: 100,
              flex: 1,
            },
            {
              field: "created_at",
              headerName: translations[selected_locale].created_at,
              minWidth: 150,
              flex: 1,
              valueFormatter: (params) =>
                `${dayjs(params.value).format("DD-MM-YYYY HH:ss")}`,
            },
            {
              field: "updated_at",
              headerName: translations[selected_locale].updated_at,
              minWidth: 150,
              flex: 1,
              valueFormatter: (params) =>
                `${dayjs(params.value).format("DD-MM-YYYY HH:ss")}`,
            },
            {
              field: "actions",
              sortable: false,
              filterable: false,
              headerName: "",
              minWidth: 150,
              renderCell: (params) => (
                <>
                  <Button
                    color="secondary"
                    variant="outlined"
                    to={`/client/${params.id}`}
                  >
                    {translations[selected_locale].update}
                  </Button>
                </>
              ),
            },
          ]}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          localeText={translations[selected_locale].datagrid}
        />
      </Container>
    </>
  );
};

export default ClientPage;
